import React from "react";
import logo from "./assets/logo.png";
import product from "./assets/product.png";
import "./App.css";

function App() {
  return (
    <div className="AppWrapper">
      <div className="App">
        <div className="ImagesContainer">
          <img src={logo} className="App-logo" alt="logo" />
          <img
            width={"300vw"}
            src={product}
            className="App-product"
            alt="product"
          />
        </div>
        <div className="ContactsContainerWrapper">
          <div className="ContactsContainer">
            <div className="ContactContainer">
              <p className="AvailableSoon">Brevemente disponível!</p>
            </div>
          </div>
          <div className="ContactsContainer">
            <div className="ContactContainer">
              <p className="ContactSubstitle">
              <b>Contactos:</b> Avenida Dr. Arménio Maia, nº 58, 2º dto 3680-115 Oliveira de
                Frades | info.plughere@gmail.com
              </p>
            </div>
          </div>
          <div className="ContactsContainer">
            <div className="ContactContainer">
              <p className="ContactSubstitle" style={{
                paddingTop: '3vh',
                fontSize: '.75rem',
                fontWeight: 'bolder'
              }}>© 2024 Inv2Grau, Lda. Todos os direitos reservados.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
